import React from "react";
import HomeContentDesktop from "./HomeContentDesktop";
import Layout from "../../Layout/Layout";

import Splash from "./Splash";

function Home() {
  return (
    <Layout>
      <Splash />
      <HomeContentDesktop />
    </Layout>
  );
}

export default Home;
