import React from "react";
import styled from "styled-components";
import { Button } from "@mui/material";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
`;

const Message = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: black; /* Updated to black */
`;

const HomeButton = styled(Button)`
  && {
    background-color: black; /* Updated to black */
    color: white; /* You can adjust the button text color if needed */
    margin-top: 20px;
  }
`;

const NotFoundPage = () => (
  <PageContainer>
    <VideoBackground autoPlay loop muted>
      <source src="/videos/not-found.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </VideoBackground>
    <Message>Oops, something went wrong...</Message>
    <HomeButton
      variant="contained"
      onClick={() => (window.location.href = "/")}
    >
      Return Home
    </HomeButton>
  </PageContainer>
);

export default NotFoundPage;
