import React, { createContext, useContext } from "react";
import { useGLTF } from "@react-three/drei";

const ModelContext = createContext();

export function ModelProvider({ children }) {
  const { nodes } = useGLTF("/splash.glb"); // Update the path to your new GLB file

  return (
    <ModelContext.Provider value={nodes}>{children}</ModelContext.Provider>
  );
}

export function useModel() {
  return useContext(ModelContext);
}
