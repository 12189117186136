import React from "react";
import styled from "styled-components";
import Logo from "../Components/Logo";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  return (
    <FooterContainer>
      <LogoContainer>
        <LogoWrapper onClick={scrollToTop}>
          {" "}
          {/* Add onClick here */}
          <Logo fill="#fff" size="200px" />
        </LogoWrapper>
      </LogoContainer>
      <SocialIcons>
        <a
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon className="social-icon" />
        </a>
        <a
          href="https://linkedin.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon className="social-icon" />
        </a>
      </SocialIcons>
      <NavLinks>
        <a href="/contact">Contact</a>
      </NavLinks>
      <FooterBottom>
        <p>
          &copy; {new Date().getFullYear()} Section Studio. All rights reserved.
        </p>
      </FooterBottom>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 150px 0 20px 0;
  text-align: center;
  font-family: Arial, sans-serif;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Show pointer to indicate it's clickable */
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  .social-icon {
    margin: 0 10px;
    color: #bfbfbf;
    cursor: pointer;

    &:hover {
      color: #0073b1; /* LinkedIn color */
    }

    &:nth-child(2):hover {
      color: #e1306c; /* Instagram color */
    }
  }
`;

const NavLinks = styled.div`
  margin-bottom: 20px;

  a {
    margin: 0 15px;
    color: #808080;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterBottom = styled.div`
  font-size: 14px;
  color: #404040;
  margin-top: 4rem;
`;

export default Footer;
