import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";

import Contact from "./Pages/Contact";
import NotFoundPage from "./Pages/NotFoundPage"; // Import the 404 page

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/contact" element={<Contact />} />
      {/* Catch-all route for undefined paths */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
