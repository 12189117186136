import React from "react";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
import Logo from "../Components/Logo";
// import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
// import Tooltip from "@mui/material/Tooltip";
// import { styled as muiStyled } from "@mui/material/styles";
// import { useResponsive } from "../Components/responsive";

// const LightBlueTooltip = muiStyled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))`
//   & .MuiTooltip-tooltip {
//     background-color: transparent;
//     color: black;
//     font-size: 1rem;
//     border: 2px solid #000;
//   }
// `;

const Header = () => {
  const location = useLocation();
  // const { isXsm, isSm, isMd, isLg, isXl, is2Xl, isAbove2Xl } = useResponsive();

  return (
    <HeaderContainer>
      {location.pathname !== "/" && (
        <LogoWrapper>
          <Link to="/">
            <Logo fill="#fff" size="200px" />
          </Link>
        </LogoWrapper>
      )}
      {/* Add other header content here */}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: #fff;
  padding: 0;
  z-index: 10;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  width: 200px;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export default Header;
