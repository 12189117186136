import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles.css";
import App from "./App";
import { initGA, logPageView } from "./analytics";

function setVh() {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`
  );
}

window.addEventListener("resize", setVh);
window.addEventListener("orientationchange", setVh);
setVh();

const root = createRoot(document.getElementById("root"));

initGA();
logPageView();

root.render(
  <Router>
    <App />
  </Router>
);
