import React, { useState, useRef, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Float, MeshTransmissionMaterial, Preload } from "@react-three/drei";
import { useMediaQuery } from "react-responsive";
import { ModelProvider } from "../../Models/ModelLoader";
import { useModel } from "../../Models/ModelLoader";
import styled from "styled-components";
import * as THREE from "three"; // Import THREE
import { useResponsive } from "../../Components/responsive";

import { EffectComposer, Bloom } from "@react-three/postprocessing";

const CanvasWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh; /* Make the canvas take full viewport height */
  overflow: hidden; /* Ensure no overflow */
`;

const OverlayText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: ${(props) =>
    props.isXsm ? "50%" : props.isSm ? "20%" : "15%"};
  overflow: hidden;

  z-index: 10; /* Ensure it appears above the canvas */

  h2 {
    font-size: ${(props) =>
      props.isXsm ? "1rem" : props.isSm ? "1.5rem" : "2rem"};
    font-weight: 200;
    background: linear-gradient(
      180deg,
      #808080 0%,
      /* 50% grey at the top */ #404040 100% /* 75% grey at the bottom */
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 100% auto; /* Static gradient without animation */
  }
`;

function cubicEase(t) {
  return t * t * (3 - 2 * t);
}

function lerp(a, b, t) {
  return a + (b - a) * t;
}

function lerp3(start, end, t) {
  const tEased = cubicEase(t);
  return [
    lerp(start[0], end[0], tEased),
    lerp(start[1], end[1], tEased),
    lerp(start[2], end[2], tEased),
  ];
}

function Splash() {
  const [perfSucks, degrade] = useState(false);
  const bgColor = "black";
  const cameraRef = useRef(null);
  const mouseRef = useRef({ x: 0, y: 0 });

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const initialZ = isMobile ? 25 : 14;

  const { isXsm, isSm, isMd, isLg, isXl } = useResponsive();

  const handleMouseMove = (event) => {
    mouseRef.current = {
      x: (event.clientX / window.innerWidth) * 2 - 1,
      y: -(event.clientY / window.innerHeight) * 2 + 1,
    };
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <CanvasWrapper>
      <OverlayText isXsm={isXsm} isSm={isSm}>
        <h2>creative VFX workflows</h2>
      </OverlayText>
      <ModelProvider>
        <Canvas
          shadows
          dpr={[1, perfSucks ? 1.5 : 2]}
          camera={{ position: [0, 0, initialZ], fov: 35, near: 1, far: 45 }}
          gl={{
            toneMapping: THREE.LinearToneMapping,
            outputEncoding: THREE.sRGBEncoding,
          }}
          style={{ width: "100%", height: "100%" }}
          onCreated={({ camera }) => {
            cameraRef.current = camera;
            camera.position.set(0, 0, initialZ);
          }}
        >
          <EffectComposer>
            <Bloom
              luminanceThreshold={0.1}
              mipmapBlur
              luminanceSmoothing={0.0}
              intensity={0.15}
            />
          </EffectComposer>
          <color attach="background" args={[bgColor]} />

          <PerformanceMonitor onDecline={() => degrade(true)} />
          <Float
            rotationIntensity={isXsm ? 2 : 1} // Increase on touch devices
            floatIntensity={isXsm ? 3 : 1} // Increase on touch devices
            speed={isXsm ? 2 : 1} // Increase on touch devices
          >
            <Logo position={[0, 1, 0]} rotation={[0, 0, 0]} />
          </Float>

          <Preload all />
          <CameraControls
            mouseRef={mouseRef}
            perfSucks={perfSucks}
            initialZ={initialZ}
          />
        </Canvas>
      </ModelProvider>
    </CanvasWrapper>
  );
}

function PerformanceMonitor({ onDecline }) {
  useFrame(() => {
    // Performance monitoring logic can be implemented here
  });
  return null;
}

function CameraControls({ mouseRef, perfSucks, initialZ }) {
  const { camera } = useThree();
  const targetFocusRef = useRef([0, 0, 0]);

  useFrame(() => {
    if (!perfSucks) {
      const targetPosition = {
        x: mouseRef.current.x * -10,
        y: mouseRef.current.y * -10,
        z: initialZ,
      };

      const limits = {
        xMin: -14,
        xMax: 14,
        yMin: -5,
        yMax: 5,
      };

      targetPosition.x = Math.max(
        limits.xMin,
        Math.min(limits.xMax, targetPosition.x)
      );
      targetPosition.y = Math.max(
        limits.yMin,
        Math.min(limits.yMax, targetPosition.y)
      );

      const newTargetFocus = [0, 0, 0];

      const newCameraPosition = lerp3(
        [camera.position.x, camera.position.y, camera.position.z],
        [targetPosition.x, targetPosition.y, targetPosition.z],
        0.1
      );
      camera.position.set(...newCameraPosition);

      targetFocusRef.current = lerp3(
        targetFocusRef.current,
        newTargetFocus,
        0.1
      );
      camera.lookAt(...targetFocusRef.current);
    }
  });

  return null;
}

function Logo({ children, ...props }) {
  const nodes = useModel();

  return (
    <group {...props} dispose={null}>
      {/* Render the 'logo' mesh */}
      <mesh scale={[10, 10, 10]} geometry={nodes.logo.geometry}>
        <meshStandardMaterial
          color="white"
          emissive="white"
          emissiveIntensity={5} // Adjust as needed
          // Remove toneMapped={false}
        />
      </mesh>

      {/* Render the 'lens' mesh with glass-like material */}
      <mesh
        castShadow
        scale={[15, 15, 15]}
        position={[0, 0, 2]}
        geometry={nodes.lens.geometry}
        rotation={[0, 0, 0]}
      >
        <MeshTransmissionMaterial
          thickness={0.1}
          roughness={0}
          transmission={1}
          ior={1.15}
          chromaticAberration={0.05}
          backside={true}
          samples={64}
          distortion={0}
          anisotropy={1}
          temporalDistortion={2}
        />
      </mesh>

      <group>{children}</group>
    </group>
  );
}

export default Splash;
