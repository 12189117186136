import React from "react";
import styled from "styled-components";
import { useResponsive } from "../../Components/responsive";
import Balancer from "react-wrap-balancer";

function HomeContentDesktop() {
  const { isXsm, isSm, isMd, isLg, isXl, is2Xl } = useResponsive();

  const videoSrc = isXsm || isSm ? "/videos/demo4-5.mp4" : "/videos/demo.mp4";

  return (
    <HomeContentContainer>
      <SectionTwo>
        <StyledVideo
          className="background-video"
          playsInline
          autoPlay
          loop
          muted
          controls={false}
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
      </SectionTwo>
    </HomeContentContainer>
  );
}

const HomeContentContainer = styled.div`
  max-width: 1440px;
`;

const SectionTwo = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 10rem 0;
  width: 100%;
`;

const StyledVideo = styled.video`
  width: 80%;
  height: auto;
  border-radius: 8px;
`;

export default HomeContentDesktop;
