import { useWindowSize } from "react-use";

export const breakpoints = {
  xsm: 390, // Extra small
  sm: 640, // Small
  md: 768, // Medium
  lg: 1024, // Large
  xl: 1280, // Extra large
  "2xl": 1440, // 2x Extra large
};

export const useResponsive = () => {
  const { width } = useWindowSize();

  const isXsm = width <= breakpoints.xsm;
  const isSm = width > breakpoints.xsm && width <= breakpoints.sm;
  const isMd = width > breakpoints.sm && width <= breakpoints.md;
  const isLg = width > breakpoints.md && width <= breakpoints.lg;
  const isXl = width > breakpoints.lg && width <= breakpoints.xl;
  const is2Xl = width > breakpoints.xl && width <= breakpoints["2xl"];
  const isAbove2Xl = width > breakpoints["2xl"];

  return { isXsm, isSm, isMd, isLg, isXl, is2Xl, isAbove2Xl, width };
};
